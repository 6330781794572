.settings {
  padding: env(safe-area-inset-top) 1.25rem 0;
  background-color: var(--gray-0);
}

.header {
  display: flex;
  align-items: center;
  height: 4.25rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.back {
  height: .65rem;
  margin: 0 1rem 2px 0;
}

.logout {
  width: 6rem;
  padding: 1.25rem 0;
  color: var(--red-200);
  background: transparent;
}
