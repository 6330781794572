.wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 192px);
  margin: 0 1.25rem;
}

.list {
  margin: 0 auto;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 4rem 2.5rem 0;
  gap: 2rem;
}

.item {
  display: flex;
  flex-direction: column;
  color: var(--gray-300);
  font-size: .8125rem;
  text-transform: uppercase;
}

.spacer {
  flex: 1;
}

.value {
  margin-top: .25rem;
  color: var(--gray-500);
  font-size: 1rem;
  text-transform: none;
}

.button {
  margin-bottom: 1.25rem;
}
