.dialog {
  top: 0;
  background-color: var(--gray-0);
}

.header {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(3.5rem + env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
  color: var(--gray-0);
  background: url("../../../assets/EQUANS_background.jpg") bottom;
  background-size: cover;
  font-size: .75rem;
  text-transform: uppercase;
  z-index: 100;
}

.close {
  position: absolute;
  left: 1.25rem;
}

.content {
  margin-top: calc(3.5rem + env(safe-area-inset-top));
  height: calc(100vh - 3rem);
  overflow-y: scroll;
}

.back {
  height: .65rem;
}
