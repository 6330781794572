.container {
  margin: calc(5.5rem + env(safe-area-inset-top)) 0 1.5rem;
}

.date {
  margin: 2.5rem 1.25rem 1rem;
  color: var(--gray-300);
  font-size: .8125rem;
  text-transform: uppercase;
}

.spinner {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}
