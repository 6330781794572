@import "../variables";

.header {
  position: fixed;
  display: flex;
  align-items: center;
  // gap: 1.5rem;
  top: env(safe-area-inset-top);
  width: 100%;
  height: 4.25rem;
  padding: 0 1.25rem;
  background-color: var(--gray-0);
  font-size: 1.25rem;
  z-index: 100;

  &::after {
    content: "";
    position: fixed;
    display: block;
    top: 0;
    width: 100%;
    height: env(safe-area-inset-top);
    background: url("../assets/EQUANS_background.jpg") bottom;
    background-size: cover;
  }
}

.shadow {
  box-shadow: $shadow-08;
}

.spacer {
  flex: 1;
  margin-left: -1.5rem;
}

.action {
  margin-left: 1.5rem;
  color: var(--blue-300);
}
