.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  background: linear-gradient(270deg, rgba(11, 151, 53, 0.288), rgba(44, 204, 145, 0.82));
}

.spinner {
  margin: auto;
  align-self: center;
  width: 2.5rem;
  height: 2.5rem;
  border: 3px solid var(--gray-100);
  border-right-color: transparent;
  border-radius: 50%;
}

.text {
  text-align: center;
  margin: 1rem auto;
  color: var(--gray-100);
}

.spinnerContainer {
  margin: auto;
}